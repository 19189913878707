@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $white;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: futura-pt, sans-serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $black;
}

a {
	color: $black;

	@include hover-focus {
		color: $black;
	}
}

header {

	padding: 20px;
	.container{
		text-align: center;
		img{
			display: inline-block;
			margin-bottom: 30px;
		}
		.menu{
			font-size: 14px;
			text-transform: uppercase;
			list-style: none;
			padding: 0;
			margin: 0;
			@include flex(column,initial,initial);
			li{
				@include flex(column,initial,initial);
				padding:  20px 0;
				button{
					text-transform: uppercase;
					border: none;
					padding: 0;
					background-color: transparent;
					color: $black;
					opacity: .5;
					&.active{
						font-weight: 700;
						opacity: 1;
					}
				}
				a{
					padding: 30px 0 0;
					text-decoration: underline;
					display: none;
					@include hover-focus{
						opacity: .5;
					}
				}
			}
		}
	}

	@include tablet-up{
		@include flex(column,center,center);
		min-height: 100vh;
		.container{
			img{
				margin-bottom: 50px;
			}
			.menu{
				@include flex(row,center,initial);
				gap: 0;
				li{
					flex: 1;
					position: relative;
					a{
						position: absolute;
						width: 100%;
						padding: 0;
						bottom: -30px;
						width: 220px;
						left: calc(50% - 110px);
					}
				}
			}
		}
	}

}

main {

}

footer {

}